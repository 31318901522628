import React from "react";
// Customizable Area Start
import { Box, Grid, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import Footer from "../../../components/src/footer.web";
import Header from "../../../components/src/Header.web";
import { leftArrow, showMappig } from "./assets";
import Loader from "./Loader.web";
// Customizable Area End

import LandingPageViewReportClaimsController, {
  Props,
} from "./LandingPageViewReportClaimsController";

export default class LandingPageViewReportClaims extends LandingPageViewReportClaimsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  detailsContant = () => {
    const { detailsData } = this.state
    return <Box>
      <Box className="keyFeature-main-text">
        {detailsData?.title}
      </Box>
      {detailsData?.images && detailsData?.images?.length !== 0 && <Box className="keyFeature-img-main">
        {detailsData?.images.map((image) => (
          <Box className="img-loop">
            <img src={image} className="img-class" alt="keyFeatureImg" />
          </Box>
        ))}
      </Box>}
      <Box>
        <Box className="descriptionStyle" id="heading-text">Description</Box>
        <Box className="description-text">{detailsData?.abstract}</Box>
      </Box>
      <Box className="grid-main-box">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Box className="claims-main-box">
              <Box className="landingPage-claims-Id-Box">
                <Box className="claims-Id-text">{detailsData?.publication_number}</Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="claims-box">
              <Box>
                <Box className="InventorStyle">Inventor</Box>
                {
                  detailsData?.inventor?.map((invent: { name: string }) => (
                    <Box className="Inventor-text">{invent.name}, </Box>
                  ))
                }
              </Box>
              <Box>
                <Box className="CurrentAssigneeStyle">Current Assignee</Box>
                {detailsData?.assignee && Array.isArray(detailsData.assignee) &&
                  detailsData?.assignee?.map((assign: string) => (
                    <Box className="CurrentAssignee-text">{assign}</Box>
                  ))
                }

              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="worldwide-Box">
              <Box className="worldwideStyle">Worldwide applications</Box>
              <Box className="woldwide-text-Box">
                {Object.keys(detailsData?.worldwide_applications ?? {})?.map((value) => (
                  <>
                    <Box className="years-text">
                      <Box component="span" className="years-bold">{value} - </Box>
                      {detailsData?.worldwide_applications[value].reduce((uniqueCountries, application) => {
                        if (!uniqueCountries.includes(application?.country_code as never)) {
                          uniqueCountries.push(application?.country_code as never);
                        }
                        return uniqueCountries;
                      }, []).join(' ')}
                    </Box>
                  </>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className="events-box">
              <Box>
                <Box className="worldwideStyle">Application {detailsData?.application_number} events</Box>
                {detailsData?.events && <Box component={"ul"} className="events-text-Box">
                  {detailsData?.events?.map((event) => (
                    <Box className="years-text" component={"li"}><Box component={"span"} className="years-bold">{event?.date ? event.date.replace(/-/g, "/") : "Date not available"} - </Box> {event?.title}</Box>
                  ))}
                </Box>}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className="events-box">
              <Box>
                <Box className="worldwideStyle">Classification</Box>
                {detailsData?.classifications ? <Box className="information-text-Box" component={"ul"}>
                  {
                    detailsData?.classifications?.map((classification) => (
                      <Box component={"li"} className="classification-text"><Box component={"span"} className="information-Text">{classification?.code} - </Box> {classification?.description}</Box>
                    ))
                  }
                </Box> : <Typography className="classification-error">No Data Found</Typography>}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className="buttons-box" id="show-mapping" onClick={() => this.goToShowMappingPage()}>
              <img src={showMappig} alt="showMappig" />
              <Box className="button-text">
                Show Mapping
              </Box>
            </Box>
          </Grid>
          {this.state.tabs.map((item: { name: string }, index: number) => (
            <Grid item xs={12} md={4}>
              <Box key={index} className={item.name !== this.state.tabsName ? "tab-grid-box" : "tab-grid-box-select"}
                onClick={() => this.onClickTabChange(item.name)} id={`tabs${index}`}>
                <Box className={item.name !== this.state.tabsName ? "tab-grid-text" : "tab-grid-text-select"}>{item.name === "Claims" ? `Claims (${detailsData?.claims?.length})` : item.name}</Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      {this.state.tabsName === "Claims" && (
        <Box className="invention-main-Box">
          <Box className="invention-main-text">The invention claimed is:</Box>
          <Box className="invention-text-box">
            {detailsData?.claims?.map((claim) => (
              <Box className="invention-text">{claim}</Box>
            ))}
          </Box>
        </Box>
      )}
      {this.state.tabsName === "Description" && detailsData?.description && (
        <Box className="technical-main-Box">
          <Box className="description-content" dangerouslySetInnerHTML={{ __html: detailsData?.description }}></Box>
        </Box>
      )}
      {this.state.tabsName === "Information" && (
        <Box className="invention-main-Box">
          <Box marginY={5}>
            <Box className="invention-main-text">Patent Citations ({detailsData?.patent_citations?.original?.length})</Box>
          </Box>
          <TableContainerCss>
            <TableContainer className="table-mxHeight">
              <Table stickyHeader aria-label="sticky table" className="table-main">
                <TableHead>
                  <TableRow>
                    <TableCell>Publication number</TableCell>
                    <TableCell>Priority date</TableCell>
                    <TableCell>Publication date</TableCell>
                    <TableCell>Assignee</TableCell>
                    <TableCell>Title</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detailsData?.patent_citations?.original?.map((citation, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {citation.publication_number}
                      </TableCell>
                      <TableCell>{citation.priority_date}</TableCell>
                      <TableCell>{citation.publication_date}</TableCell>
                      <TableCell>{citation.assignee_original}</TableCell>
                      <TableCell>{citation.title}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box marginY={5}>
              <Box className="invention-main-text">Cited By ({detailsData?.cited_by?.family_to_family?.length})</Box>
            </Box>
            <Table stickyHeader aria-label="sticky table" className="table-main">
              <TableHead>
                <TableRow>
                  <TableCell>Publication number</TableCell>
                  <TableCell>Priority date</TableCell>
                  <TableCell>Publication date</TableCell>
                  <TableCell>Assignee</TableCell>
                  <TableCell>Title</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {detailsData?.cited_by?.original?.map((original, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {original.publication_number}
                    </TableCell>
                    <TableCell>{original.priority_date}</TableCell>
                    <TableCell>{original.publication_date}</TableCell>
                    <TableCell>{original.assignee_original}</TableCell>
                    <TableCell>{original.title}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box className="sub-table-head">
              Family To Family Citations
            </Box>
            <TableBody>
              {detailsData?.cited_by?.family_to_family?.map((family, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {family.publication_number}
                  </TableCell>
                  <TableCell>{family.priority_date}</TableCell>
                  <TableCell>{family.publication_date}</TableCell>
                  <TableCell>{family.assignee_original}</TableCell>
                  <TableCell>{family.title}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <Box marginY={5}>
              <Box className="invention-main-text">Similar Documents</Box>
            </Box>
            <TableContainer className="table-mxHeight">
              <Table stickyHeader aria-label="simple table" className="table-main">
                <TableHead>
                  <TableRow>
                    <TableCell>Publication</TableCell>
                    <TableCell>Publication Date</TableCell>
                    <TableCell>Title</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detailsData?.similar_documents?.map((citation, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {citation.publication_number}
                      </TableCell>
                      <TableCell>{citation.publication_date}</TableCell>
                      <TableCell>{citation.title}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box marginY={5}>
              <Box className="invention-main-text">Priority And Related Applications</Box>
            </Box>

            <Box marginBottom={2}>
              <Box className="invention-main-text">Parent Applications ({detailsData?.parent_applications?.length})</Box>
            </Box>
            <TableContainer className="table-mxHeight">
              <Table stickyHeader aria-label="simple table" className="table-main">
                <TableHead>
                  <TableRow>
                    <TableCell>Application</TableCell>
                    <TableCell>Priority date</TableCell>
                    <TableCell>Filing date</TableCell>
                    <TableCell>Relation</TableCell>
                    <TableCell>Title</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detailsData?.parent_applications?.map((parent, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {parent.application_number}
                      </TableCell>
                      <TableCell>{parent.priority_date}</TableCell>
                      <TableCell>{parent.filing_date}</TableCell>
                      <TableCell>{parent.relation_type}</TableCell>
                      <TableCell>{parent.title}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box marginY={2}>
              <Box className="invention-main-text">Priority Applications ({detailsData?.priority_applications?.length})</Box>
            </Box>
            <TableContainer className="table-mxHeight">
              <Table stickyHeader aria-label="simple table" className="table-main">
                <TableHead>
                  <TableRow>
                    <TableCell>Application</TableCell>
                    <TableCell>Priority date</TableCell>
                    <TableCell>Filing date</TableCell>
                    <TableCell>Title</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detailsData?.priority_applications?.map((priority, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {priority.application_number}
                      </TableCell>
                      <TableCell>{priority.priority_date}</TableCell>
                      <TableCell>{priority.filing_date}</TableCell>
                      <TableCell>{priority.title}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box marginY={2}>
              <Box className="invention-main-text">Applications Claiming Priority ({detailsData?.applications_claiming_priority?.length})</Box>
            </Box>
            <TableContainer className="table-mxHeight">
              <Table stickyHeader aria-label="simple table" className="table-main">
                <TableHead>
                  <TableRow>
                    <TableCell>Application</TableCell>
                    <TableCell>Filing date</TableCell>
                    <TableCell>Title</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detailsData?.applications_claiming_priority?.map((appClaiming, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {appClaiming.application_number}
                      </TableCell>
                      <TableCell>{appClaiming.filing_date}</TableCell>
                      <TableCell>{appClaiming.title}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box marginY={2}>
              <Box className="invention-main-text">Legal Events</Box>
            </Box>
            <TableContainer className="table-mxHeight">
              <Table stickyHeader aria-label="simple table" className="table-main">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Code</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detailsData?.legal_events?.map((legal, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {legal.date}
                      </TableCell>
                      <TableCell>{legal.code}</TableCell>
                      <TableCell>{legal.title}</TableCell>
                      <TableCell><span className="legal-lable">{legal.attributes?.[0]?.label}:</span> {legal.attributes?.[0]?.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TableContainerCss>
        </Box>
      )}
    </Box>
  }

  noResultFound = () => {
    return !this.state.loading && <Box component={"div"} className="no-result-text">No Results Found</Box>
  }
  // Customizable Area End

  render() {
    return (
      <>
        <Loader loading={this.state.loading} />
        <Header title={""} />
        <LandingPageViewReportClaimsCss>
          <Box className="result-Main">
            <Box className="back-arrow-box">
              <img
                src={leftArrow}
                alt="leftArrow"
                id="back-results"
                className="back-arrow-img"
                onClick={() => this.goBackToKeyFeaturePage()}
              />
              <Box className="back-arrow-text">Back to results</Box>
            </Box>
            <Box className="landingPageClaims">
              {!this.state.loading && this.state.detailsData ?
                this.detailsContant()
                :
                this.noResultFound()
              }
            </Box>
          </Box>
        </LandingPageViewReportClaimsCss>
        <Footer />
      </>
    );
  }
}

// Customizable Area Start
const TableContainerCss = styled(Box)({
  "& .table-mxHeight":{
    maxHeight:"700px"
  },
  "& .table-main": {
    minWidth: 650,
    "& .MuiTableCell-alignRight": {
      textAlign: "left"
    },
    "& .MuiTableCell-head": {
      background: "#D3D3D3",
      color: "#4F5356",
      fontSize: "16px",
      fontFamily: "Inter",
      fontWeight: 500,
      lineHeight: "26px",
    },
  },
  "& .MuiTableCell-body": {
    color: "#4F5356",
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: 400,
    lineHeight: "26px",
    background: "#E3DFDF",
  },
  "& .MuiTableCell-root": {
    borderBottom: "1px solid #f0f0f0"
  },
  "& .sub-table-head": {
    background: "#D3D3D3",
    color: "#4F5356",
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: 500,
    lineHeight: "26px",
    padding: "20px"
  },
  "& .legal-lable": {
    color: "#4F5356",
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: 500,
    lineHeight: "26px",
    background: "#E3DFDF",
  }
})
const LandingPageViewReportClaimsCss = styled(Box)({
  "& .result-Main": {
    background: "#E8E7E7",
    padding: "20px 108px",
    "@media(max-width:1050px)": {
      padding: "40px 40px",
    },
    "@media(max-width:920px)": {
      padding: "40px 20px",
    },
  },
  "& .img-class": {
    height: 172,
    width: 166,
  },
  "& .back-arrow-box": {
    alignItems: "center",
    display: "flex",
    gap: 16
  },
  "& .back-arrow-img": {
    cursor: "pointer"
  },
  "& .back-arrow-text": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "18px",
    color: "#212324"
  },
  "& .landingPageClaims": {
    marginTop: "24px"
  },
  "& .no-result-text": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: 24,
    display: "flex",
    justifyContent: "center",
    height: "200px",
    alignItems: "center"
  },
  "& .keyFeature-img-main": {
    background: "#F5F5F4",
    padding: "24px 24px 30px 24px",
    gap: "24px",
    flexWrap: "wrap",
    display: "flex",
    borderRadius: "8px",
    height: "167px",
    overflowY: "scroll"
  },
  "& .img-loop": {
    height: "186px",
    width: "176px",
    objectFit: "contain"
  },
  "& .keyFeature-main-text": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: 24,
    marginBottom: 24,
    color: "#212324"
  },
  "& .box-span": {
    color: "#DF4833"
  },
  "& .descriptionStyle": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: 20,
    margin: "24px 0",
    color: "#212324"
  },
  "& .description-text": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: 20,
    color: "#4F5356"
  },
  "& .claims-main-box": {
    display: "flex",
    flexDirection: "column",
    gap: "30px"
  },
  "& .landingPage-claims-Id-Box": {
    borderRadius: 8,
    background: "#FCEDEB",
  },
  "& .claims-Id-text": {
    fontWeight: 700,
    color: "#DF4833",
    fontFamily: "Inter",
    fontSize: 24,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    padding: "24px",
  },
  "& .claims-box": {
    background: "#F5F5F4",
    padding: "24px",
    borderRadius: "8px",
    minHeight: "196px",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  "& .InventorStyle": {
    fontSize: "20px",
    color: "#DF4833",
    lineHeight: "28px",
    fontWeight: 700,
    marginBottom: "5px",
    fontFamily: "Inter",
  },
  "& .Inventor-text": {
    fontFamily: "Inter",
    fontSize: "18px",
    color: "#4F5356",
    lineHeight: "28px",
    fontWeight: 400,
  },
  "& .link-text": {
    textDecoration: "none",
    color: "#3B82F6"
  },
  "& .CurrentAssigneeStyle": {
    fontFamily: "Inter",
    marginBottom: "5px",
    lineHeight: "28px",
    fontWeight: 700,
    color: "#DF4833",
    fontSize: "20px",
  },
  "& .CurrentAssignee-text": {
    lineHeight: "28px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "18px",
    color: "#4F5356",
  },
  "& .worldwideStyle": {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 700,
    color: "#DF4833",
  },
  "& .woldwide-text-Box": {
    gap: "7px",
    display: "flex",
    flexDirection: "column"
  },
  "& .worldwide-Box": {
    background: "#F5F5F4",
    padding: "24px",
    borderRadius: "8px",
    minHeight: "196px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    height:196,
    overflowY:"scroll"
  },
  "& .years-text": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "18px",
    color: "#4F5356",
    lineHeight: "26px"
  },
  "& .years-bold": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "18px",
    color: "#4F5356",
  },
  "& .grid-main-box": {
    marginTop: 30,
  },
  "& .events-Box": {
    background: "#F5F5F4",
    padding: "24px",
    borderRadius: "8px",
    display: "flex",
    height: "382px",
    overflowY: "scroll",
    flexDirection: "column",
  },
  "& .events-text-Box": {
    gap: "20px",
    display: "flex",
    flexDirection: "column"
  },
  "& .show-btn-Box": {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    cursor: "pointer"
  },
  "& .show-btn-text": {
    fontFamily: "poppins",
    fontWeight: 600,
    fontSize: 18,
    color: "#4F5356"
  },
  "& .information-text-Box": {
    gap: "10px",
    display: "flex",
    flexDirection: "column"
  },
  "& .information-Text": {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 700,
    color: "#3B82F6"
  },
  "& .classification-text": {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "26px",
    color: "#4F5356",
    fontFamily: "Inter"
  },
  "& .landscapes-main-box": {
    background: "#F0F0F0",
    borderRadius: 8,
    alignItems: "center",
    display: "flex",
    padding: "15px 10px",
    margin: "10px 0"
  },
  "& .landscapes-box": {
    display: "flex",
    alignItems: "center",
    gap: 5
  },
  "& .landscapes-text": {
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 400,
    color: "#4F5356"
  },
  "& .tab-grid-box": {
    height: "46px",
    background: "#F1F5F9",
    borderRadius: 4,
    padding: "4px 8px 4px 12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  "& .tab-grid-box-select": {
    cursor: "pointer",
    height: "46px",
    background: "#FCEDEB",
    borderRadius: 4,
    padding: "4px 8px 4px 12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  "& .tab-grid-text": {
    fontWeight: 400,
    cursor: "pointer",
    color: "#64748B",
    fontFamily: "Inter",
    fontSize: 14,
  },
  "& .tab-grid-text-select": {
    fontSize: 14,
    fontFamily: "Inter",
    color: "#DF4833",
    cursor: "pointer",
    fontWeight: 400,
  },
  "& .invention-main-Box": {
    marginTop: 16
  },
  "& .invention-main-text": {
    color: "#212324",
    fontFamily: "Inter",
    fontSize: 24,
    fontWeight: 700,
  },
  "& .invention-text-box": {
    display: "flex",
    flexDirection: "column",
    gap: "18px",
  },
  "& .invention-text": {
    fontFamily: "Inter",
    fontSize: 20,
    lineHeight: "28px",
    fontWeight: 400,
    color: "#4F5356"
  },
  "& .invention-peragraph": {
    lineHeight: "28px",
    fontFamily: "Inter",
    fontSize: 20,
    fontWeight: 400,
    color: "#4F5356"
  },
  "& .description-chart": {
    background: "#F5F5F4",
    padding: "24px",
    borderRadius: "8px",
    display: "flex",
    minHeight: "382px",
    flexDirection: "column",
  },
  "& .technical-main-Box": {
    marginTop: 16,
    display: "flex",
    flexDirection: "column",
    gap: 10
  },
  "& .description-content": {
    fontFamily: "Inter",
    color: "#4F5356",
    fontSize: 20,
    lineHeight: "28px",
    fontWeight: 400,
    "& heading": {
      fontWeight: 700,
      color: "#212324",
      marginBlock: 15
    },
    "& div": {
      marginBlock: 10
    },
    "& img": {
      height: 100,
      width: 200
    }
  },
  "& .technical-h-text": {
    fontFamily: "Inter",
    fontSize: 20,
    lineHeight: "28px",
    fontWeight: 700,
    color: "#212324"
  },
  "& .technical-text": {
    fontFamily: "Inter",
    fontSize: 20,
    lineHeight: "28px",
    fontWeight: 400,
    color: "#4F5356"
  },
  "& .classification-error": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height:"300px"
  },
  "& .buttons-box": {
    gap: "10px",
    cursor: "pointer",
    background: "#F1F5F9",
    borderRadius: "8px",
    // padding: " 16px",
    alignItems: "center",
    display: "flex",
    height: "46px",
    justifyContent: "center",
    padding: "4px 8px 4px 12px"
  },
  "& .button-text": {
    fontSize: "16px",
    fontWeight: 400,
    color: "#4F5356",
    fontFamily: "Inter",
  },
});
// Customizable Area End
