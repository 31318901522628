import React from "react";
// Customizable Area Start
import { Box, Dialog, Grid, IconButton, styled, Typography } from "@material-ui/core";
import {Link} from "react-router-dom"
import Footer from "../../../components/src/footer.web";
import Header from "../../../components/src/Header.web";
import { closeIcon, save, downArrowWhite, download, filterIcon, genaretingDownload, leftArrow, overlapping, showMappig, whiteCalender } from "./assets";
// Customizable Area End

import ProductShowMappingController, {
    Props,
} from "./ProductShowMappingController";

export default class ProductShowMapping extends ProductShowMappingController {
    static instance(): ProductShowMapping {
        throw new Error("Method not implemented.");
    }
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            <>
                <Header title={""} />
                <LandingPageShowMappingCss>
                    <Box className="mapping-Main">
                        <Link to={`/productdetail/${this.state.patentId}`} className="link-text" >
                        <Box className="back-arrow-box">
                            <img
                                src={leftArrow}
                                alt="leftArrow"
                                id="back-arrow"
                                className="back-arrow-img"
                            />
                            <Box className="back-arrow-text">View Results</Box>
                        </Box>
                        </Link>
                        <Box className="mapping-main-box">
                            <Box className="mapping-box">
                                <Grid item xs={12} className="mapping-search-grid">
                                    <Box className="mapping-search-box-looking" id="heading-text">
                                        Show Mapping
                                    </Box>
                                </Grid>
                                <Box className="download-Report-main">
                                    <Box className="report-buttons-box" id="download-Open" onClick={() => this.downloadOpenModel()}>
                                        <img src={download} alt="download" />
                                        <Box className="report-button-text">
                                            Download Report
                                        </Box>
                                    </Box>
                                    <Box className="save-buttons-box" id="save-result">
                                        <Box className="result-button-text">
                                            Saved Results(2)
                                        </Box>
                                        <img src={downArrowWhite} alt="download" />
                                    </Box>
                                    <Box className="filter-buttons-box" id="filter-button">
                                        <img src={filterIcon} alt="download" />
                                    </Box>
                                </Box>
                                <ReportDialog
                                    open={this.state.downloadModel}
                                    id="download-Close"
                                    aria-labelledby="customized-dialog-title"
                                    PaperProps={{
                                        className: "download-modal",
                                        elevation: 0
                                    }}
                                    fullWidth
                                    BackdropProps={{
                                        className: "backdrop-modal",
                                    }}
                                >
                                    <Box className="download-closeIconStyle">
                                        <IconButton aria-label="close">
                                            <img
                                                width={24}
                                                src={closeIcon}
                                                alt="closeIcon"
                                                onClick={() => this.downloadCloseModel()}
                                                height={24}
                                                id="download-Click-Close"
                                            />
                                        </IconButton>
                                    </Box>
                                    <Box className="down-box">
                                        <Box className="download-main-box">
                                            <img
                                                src={genaretingDownload}
                                                alt="genaretingDownload"
                                                className="download-Icon-style"
                                            />
                                            <Box>
                                                <Box className="download-title-model">
                                                    We are generating your report,
                                                </Box>
                                                <Box className="download-model-Text">
                                                    Once completed you can Download it from your Profile Settings
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </ReportDialog>
                            </Box>
                        </Box>
                        <Box className="mappingBox-Main-container">
                            <Box className="mapping-flex-box">
                                {this.state.detailsData?.mapping?.length ? (
                                    this.state.detailsData.mapping.map((item: any, index: any) => (
                                        <GridViewCss>
                                            <Grid item>
                                                <Box className="key-feature-main-box">
                                                    <Box className="box-text">
                                                        <Box component={"span"} className="box-bold-text">Key Feature: </Box> {item.keyFeature}
                                                    </Box>
                                                    <Box className="box-text">
                                                        <Box className="box-bold-text" component={"span"}>Keywords: </Box>
                                                        <Box className="box-gray-text">
                                                            <Box className="box-gray-bold-text" component={"span"}>{item.keywords.join(', ')}</Box>
                                                        </Box>
                                                    </Box>
                                                    <Box className="box-red-text">
                                                        <Box component={"span"} className="box-bold-text">Overlapping: </Box> {item.overlappingPercentage}%
                                                    </Box>
                                                </Box>

                                            </Grid>
                                            <Grid item>
                                                <Box className="key-feature-main-box">
                                                    <Box className="box-text-main">
                                                        <Box className="box1-text">
                                                            {item.matchingText.fromDescription && (
                                                                <>
                                                                    “{item.matchingText.fromDescription}”
                                                                    <Box className="box2-red-text">(refer: Description)</Box>
                                                                </>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </GridViewCss>

                                    ))
                                ) : (
                                    <Typography>Loading...</Typography> // Optional loading state
                                )}
                            </Box>
                            <Box className="mapping-buttons-main-box" sx={{ marginTop: 2 }}>
                                <Box className="mapping-buttons-box">
                                    <img src={showMappig} alt="Show Mapping" />
                                    <Typography>Show Mapping</Typography>
                                </Box>
                                <Box className="mapping-buttons-box">
                                    <img src={overlapping} alt="Overlapping" />
                                    <Typography>{this?.state.detailsData.aggregateOverlapPercentage}% Overlapping</Typography>
                                </Box>
                                <Box className="mapping-buttons-box-red">
                                    <img src={whiteCalender} alt="View More" />
                                    <Typography style={{ color: 'white' }}>View More</Typography>
                                </Box>
                                <Box className="mapping-buttons-save-box">
                                    <img src={save} alt="Save" />
                                    <Typography>Save</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </LandingPageShowMappingCss>
                <Footer />
            </>
        );
    }
}

// Customizable Area Start
const ReportDialog = styled(Dialog)({
    "& .download-modal": {
        borderRadius: 0,
        background: '#F5F5F4',
        height: 298,
        maxWidth: 738,
    },
    "& .backdrop-modal": {
        backgroundColor: 'rgba(15, 23, 42, 0.4)'
    },
    "& .download-main-box": {
        textAlign: "center",
        flexDirection: "column",
        display: "flex",
        gap: 30,
        alignItems: "center",
    },
    "& .download-Icon-style": {
        cursor: 'pointer',
    },
    "& .download-title-model": {
        color: '#4F5356',
        fontFamily: 'Inter',
        fontSize: 20,
        fontWeight: 700,
        marginBottom: "15px",
    },
    "& .download-model-Text": {
        color: '#4F5356',
        fontWeight: 700,
        fontSize: 20,
        fontFamily: 'Inter',
    },
    "& .down-box": {
        padding: 60,
        height: "100%",
    },
    "& .download-closeIconStyle": {
        top: 8,
        right: 10,
        position: 'absolute',
    },
    "& .download-close-img": {
        cursor: "pointer",
        marginLeft: 70,
    },
});

const LandingPageShowMappingCss = styled(Box)({
    "& .mapping-Main": {
        background: "#E8E7E7",
        padding: "50px 108px",
        "@media(max-width:1050px)": {
            padding: "40px 40px",
        },
        "@media(max-width:920px)": {
            padding: "40px 20px",
        },
    },
    "& .back-arrow-box": {
        alignItems: "center",
        display: "flex",
        marginBottom: "30px",
        gap: 16
    },
    "& .back-arrow-img": {
        cursor: "pointer"
    },
    "& .back-arrow-text": {
        fontSize: "18px",
        fontWeight: 400,
        fontFamily: "Inter",
        color: "#212324"
    },
    "& .download-Report-main": {
        display: 'flex',
        gap: 24,
        alignItems: 'center',
        "@media(max-width:830px)": {
            marginTop: 30
        },
        "@media(max-width:565px)": {
            flexWrap: "wrap"
        },
    },
    "& .report-buttons-box": {
        display: "flex",
        borderRadius: "8px",
        gap: "10px",
        padding: "10px 0px 10px 16px",
        background: "#EFF6FF",
        alignItems: "center",
        cursor: "pointer",
        width: "200px"
    },
    "& .filter-buttons-box": {
        display: "flex",
        borderRadius: "8px",
        gap: "10px",
        padding: "10px",
        background: "#EFF6FF",
        alignItems: "center",
        cursor: "pointer",
    },
    "& .save-buttons-box": {
        display: "flex",
        borderRadius: "8px",
        gap: "10px",
        padding: "10px 0px 10px 16px",
        background: "#DF4833",
        alignItems: "center",
        cursor: "pointer",
        width: "200px",
        justifyContent: "center"
    },
    "& .report-button-text": {
        color: "#3B82F6",
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700
    },
    "& .result-button-text": {
        color: "#FFFFFF",
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700,
    },
    "& .mapping-box": {
        display: 'flex',
        alignItems: "center",
        "@media(max-width:830px)": {
            flexWrap: "wrap",
        },
    },
    "& .mapping-search-grid": {
        display: "flex",
        justifyContent: 'space-between'
    },
    "& .mapping-search-box-looking": {
        fontFamily: "Inter",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "32px",
        color: "#212324",
        "@media(max-width:550px)": {
            flexWrap: "wrap",
        },
    },
    "& .mapping-main-box": {
        marginBottom: 30
    },
    "& .mappingBox-Main-container": {
        background: "#F5F5F4",
        borderRadius: 8,
        padding: "24px",
    },
    "& .mapping-main-text": {
        fontFamily: "Inter",
        fontWeight: 700,
        fontSize: 24,
        marginBottom: 24
    },
    "& .box-span": {
        color: "#DF4833"
    },
    "& .mapping-p-text": {
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: 16,
        color: "#4F5356",
        lineHeight: "24px",
        marginBottom: 24,
        marginTop: 15
    },
    "& .key-feature-main-box": {
        height: "100%",
        background: "#EDEEEE",
        border: "1px solid #C8CACB",
        padding: "10px 24px"
    },
    "& .mapping-flex-box": {
        display: "flex",
        marginBottom: "48px"
    },
    "& .box-text": {
        fontFamily: "Inter",
        fontSize: 16,
        color: "#000000",
        lineHeight: "24px",
        fontWeight: 400,
    },
    "& .box-bold-text": {
        fontFamily: "Inter",
        fontSize: 16,
        color: "#000000",
        lineHeight: "24px",
        fontWeight: 700,
    },
    "& .box-gray-bold-text": {
        lineHeight: "24px",
        fontWeight: 700,
        fontFamily: "Inter",
        fontSize: 16,
        color: "#4F5356",
    },
    "& .box-gray-text": {
        fontFamily: "Inter",
        fontSize: 16,
        color: "#4F5356",
        lineHeight: "24px",
        fontWeight: 400,
    },
    "& .box-red-text": {
        fontFamily: "Inter",
        fontSize: 16,
        color: "#DF4833",
        lineHeight: "24px",
        fontWeight: 400,
    },
    "& .box1-text": {
        fontFamily: "Inter",
        fontSize: 16,
        color: "#000000",
        lineHeight: "24px",
        fontWeight: 400,
        marginBottom: 5
    },
    "& .box2-red-text": {
        fontFamily: "Inter",
        fontSize: 16,
        color: "#DF4833",
        lineHeight: "24px",
        fontWeight: 700,
    },
    "& .box-text-main": {
        display: "flex",
        flexDirection: "column",
        gap: 10
    },
    "& .box2-green-text": {
        fontFamily: "Inter",
        fontSize: 16,
        color: "#10B981",
        lineHeight: "24px",
        fontWeight: 700,
    },
    "& .mapping-buttons-main-box": {
        display: "flex",
        gap: 20,
        flexWrap: "wrap"
    },
    "& .mapping-buttons-box": {
        gap: "10px",
        display: "flex",
        cursor: "pointer",
        padding: "10px 16px",
        alignItems: "center",
        background: "#F5F5F4",
        borderRadius: "8px",
        border: "1px solid #AEB0B1"
    },
    "& .mapping-buttons-save-box": {
        gap: "10px",
        display: "flex",
        marginLeft: "auto",
        cursor: "pointer",
        padding: "10px 16px",
        alignItems: "center",
        background: "#F5F5F4",
        borderRadius: "8px",
        border: "1px solid #AEB0B1",
        "@media(max-width:770px)": {
            marginLeft: "0",
        },
    },
    "& .mapping-button-text": {
        fontFamily: "Inter",
        fontSize: "16px",
        color: "#4F5356",
        fontWeight: 400
    },
    "& .mapping-buttons-box-red": {
        display: "flex",
        gap: "10px",
        padding: "10px 16px",
        cursor: "pointer",
        background: "#DF4833",
        alignItems: "center",
        borderRadius: "8px"
    },
    "& .mapping-button-text-white": {
        color: "#FFFFFF",
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "Inter",
    },
    "& .link-text": {
        textDecoration: "none"
      },
});

const GridViewCss = styled("div")({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    "@media(max-width:640px)": {
        gridTemplateColumns: "none",
    },
});
// Customizable Area End
