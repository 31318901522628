import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

export interface Section {
  index: number;
  content: string;
  title: string;
  subtitle: string;
  prompt:string
}

const sectionsIntialData: Section[] = [
  {
    title: "Summary of the invention",
    index: 5,
    subtitle: "What is the Summary for your invention?",
    content: '',
    prompt:'',
  },
  {
    subtitle: "What is the Abstract for your invention?",
    title: "Abstract of the invention",
    index: 2,
    content: '',
    prompt:'',

  },
  {
    title: "Background of the invention",
    subtitle: "What is the Background for your invention?",
    index: 3,
    content: '',
    prompt:'',
  },
  {
    title: "Title of the invention",
    content: '',
    subtitle: "What is the title for your invention?",
    index: 1,
    prompt:'',
  },
  {
    title: "Objective of the invention",
    subtitle: "What is the Objective for your invention?",
    index: 4,    
    prompt:'',
    content: '',
  },
 
];
// Define the interface for the entire API response

interface Specification 
{
  content: string | string[];
}

export interface ApiResponse 
{
  id:number;
  Specification: Specification;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
   navigation: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  redoStack: { index: number; content: string }[][];
  sections:Section[]
  rewriteindexcall:number
  undoStack: { index: number; content: string }[][];
  loading:boolean
  abstract:string
  errors:boolean
  nonprrovisonalid:number|null
  disablerewrite:boolean
  selection:string
  promptindexcall:number
  disableprompt:boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ProvisionalSpecificationPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = 
    [ 
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
     
    ];

    this.state = {
      errors:false,
      promptindexcall:0,
      undoStack:[],
      disablerewrite:false,
      nonprrovisonalid:0,
      sections:sectionsIntialData,
      loading:true,
      abstract:'',
      selection:'',
      disableprompt:false,
      rewriteindexcall:0,
      redoStack:[],
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallIdEndpoint = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJsonVariable = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const tokenExpire = responseJsonVariable.errors?.[0]?.token
    if (tokenExpire) {
      this.goToLogIn()
    }
    else if (apiRequestCallIdEndpoint === this.apiProvisonalDetailAPIEndPoint) {
      if (responseJsonVariable.Specification) {
        const sectionsData = this.mapApiResponseToSpecification(responseJsonVariable);
        this.setState({ sections: sectionsData,loading: false, nonprrovisonalid: responseJsonVariable.id })
        setStorageData("nonprrovisonalid", responseJsonVariable.id)
      }
      else  if (responseJsonVariable.errors?.length) {
        this.setState({
          loading: false,
          errors: true,
        }, () => {
          setStorageData("BackTrue", "BackTrue")
        })
      }
      else{
        this.setState({
          errors: true,loading: false
        })
      }
      setStorageData("BackTrue", "BackTrue")
    }
    else if (apiRequestCallIdEndpoint === this.apiSaveProvsonalCallID) {
      this.setState({ loading: false })
    }
    else if (apiRequestCallIdEndpoint === this.apipromptCallID) {

      this.updateSectionContentPrompt(responseJsonVariable)

    }
    else  if (apiRequestCallIdEndpoint === this.apiProvisionalSpecificationsAPIEndPoint) {
      const sectionsData = this.mapApiResponseSave(responseJsonVariable);
      this.setState({
        sections: sectionsData,
        loading: false,
        errors: false
      })
    }
    else if (apiRequestCallIdEndpoint === this.apiRewriteCallID) {

      this.updateContentBasedOnResponseRewrite(responseJsonVariable)

    }
    
    // Customizable Area End
  }

  // Customizable Area Start
  apiProvisonalDetailAPIEndPoint:string=""
  apipromptCallID:string=""
  apiRewriteCallID:string=""
  apiProvisionalSpecificationsAPIEndPoint:string=""
  apiSaveProvsonalCallID:string=""
  private interval?: NodeJS.Timeout;

  async componentDidMount() {
    const loginToke = await getStorageData("token")
    if(!loginToke){
      this.goToLogIn()
    }    

    const SearchString = await getStorageData("advanchData")

    this.setState({abstract:SearchString})

    let id = await getStorageData("nonprrovisonalid")

    this.setState({nonprrovisonalid:id})
    if(!this.state.nonprrovisonalid)
            await this.getProvisionalDetails();
    else
            await this.getSavedSPecificationNonProvisonal();
    this.interval = setInterval(this.handelSave, 5000)
  };

  
  updateSectionContentPrompt = (responseJsonVariable:any) => {
    //Prompt API
    const responseText = responseJsonVariable.Specification.join(""); 
    const indexmap = this.state.promptindexcall;
  
    this.setState(prevState => ({
      sections: prevState.sections.map((section, index) => ({
        ...section,
        prompt: index === indexmap ? responseText : section.prompt
      }))
    }), () => {
      this.setState({ 
        disableprompt: false 
      });
      this.setState({ 
        loading: false 
      });
    });
  }
  
  updateContentBasedOnResponseRewrite = (responseJsonVariable:any) => {
    //Rewrite Api Response
    if (responseJsonVariable.errors) {
      this.setState({
         errors: true 
        });
    } 
    else if(responseJsonVariable.status===500)
    {
        this.setState({ errors: true });
    } 
    else {
      const indexmap = this.state.rewriteindexcall;
      this.setState(prevState => ({
        sections: prevState.sections.map((section, index) => ({
          ...section,
          content: index === indexmap
          ? responseJsonVariable.Specification.join(' ') 
            : section.content
        }))
      }), () => {
        this.setState({ 
          disablerewrite: false 
        });
      });
    }
  }
  
  async componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
  }
  
  handleMouse = (): void => {
    const selected = window.getSelection();
    if (selected && selected.rangeCount > 0) {
      const selectedText = selected.toString().trim();
      if (selectedText) {
        this.setState({
           selection: selectedText 
          });
      }
    }
  };
  
  goToLogIn = async () => {

    removeStorageData("token");

    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );

    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );

    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  mapApiResponseToSpecification = (apiResponse: ApiResponse): Section[] => {
    // First Basic Call MAin
    const getCoString = (content: string | string[]): string => {
      let contentString;
       if (Array.isArray(content)) {
        contentString = content.join(" ");
      }
      else if (typeof content === "string") {
        contentString = content;
      }
       else {
        contentString = "";
      }
      return contentString;
    }
    
      const contentArray = Object.entries(apiResponse.Specification).map(([key, value]) => ({
        title: key,
        content: getCoString(value.content)
      }));    

      return [
        {
          
          content: contentArray[0].content,
          title: contentArray[0].title,
          subtitle: "What is the title for your invention?",
          index: 0,
          prompt:'',
        },
        {
          title:  contentArray[1].title,
          content: contentArray[1].content,
          prompt:'',
          subtitle: "What is the Background for your invention?",
          index: 1,
        
        },
        {
          subtitle: "What is the Summary for your invention?",
          index: 2,
          title: contentArray[2].title,
          content: contentArray[2].content,
          prompt:'',
        },
        {
          title:  contentArray[3].title,
          subtitle: "What is the Objective for your invention?",
          index: 3,
          content: contentArray[3].content, 
          prompt:'',
        },
        {
          title: contentArray[4].title,
          subtitle: "What is the Claim for your invention?",
          index: 4,
          content: contentArray[4].content,
          prompt:'',
        },        
        {
          title: contentArray[5].title,
          subtitle: "What is the Abstract for your invention?",
          index: 5,
          content: contentArray[5].content,
          prompt:'',
        },
      ]; 
  };


  mapApiResponseSave = (apiResponse: any): Section[] => {    
// After Saved data return on refresh
      return [
        {
          title: apiResponse.document.field_of_invention,
          content: apiResponse.document.field_of_invention_specification,
          subtitle: "What is the title for your invention?",
          index: 0,
          prompt:'',
        },
        {
          subtitle: "What is the Background for your invention?",
          index: 1,
          title:  apiResponse.document.background_of_invention,          
          content: apiResponse.document.background_of_invention_specification,
          prompt:'',
        },
        {
          title: apiResponse.document.summary_of_invention,
          subtitle: "What is the Summary for your invention?",
          index: 2,
          content: apiResponse.document.summary_of_invention_specification,
          prompt:'',
        },
        {
          subtitle: "What is the Objective for your invention?",
          index:3,
          title:  apiResponse.document.detailed_description_of_invention,         
          content: apiResponse.document.detailed_description_of_invention_specification, 
          prompt:'',
        },
        {
          title: apiResponse.document.claims,
          subtitle: "What is the Claim for your invention?",        
          prompt:'',
          index: 4,
          content: apiResponse.document.claims_specifications,
        },
        {
          title: apiResponse.document.abstract,
          subtitle: "What is the Abstract for your invention?",
          index: 5,
          content: apiResponse.document.abstract_specifications,
          prompt:'',
        },
      ]; 
  };

  getSavedSPecificationNonProvisonal = async () => {
    this.setState({ 
      loading: true 
    });
    const header = {
      token: await getStorageData("token"),
    };

    const nonprrovisonalid = this.state.nonprrovisonalid;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiProvisionalSpecificationsAPIEndPoint = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_landingpage2/provisional_specifications/${nonprrovisonalid}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handlePrompt = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, targetIndex: number) => {
    const { value } = event.target; 
    
    this.setState(prevState => ({
      sections: prevState.sections.map((section, indx) => ({
        ...section,
        prompt: indx === targetIndex ? value : section.prompt // Update content for a specificsection
      }))
    }));
    this.setState({promptindexcall:targetIndex});
  };

  ReplaceApiCall=async (Index:number)=>
  {
    
    this.setState(prevState => ({
      sections: prevState.sections.map((section, index) => ({
        ...section,
        prompt: index === Index ? "" : section.prompt,
        content:index===Index ? section.prompt:section.content,
      }))
    }));
    this.setState({promptindexcall:Index});
  }

  RewriteApiCall=async(index:number)=>{
    const title=this .state.sections[index].title 
    this.setState({disablerewrite:true})
    const content=this.state.sections[index].content;
    this.setState({rewriteindexcall:index})

    const header = 
    {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiRewriteCallID = requestMessage.messageId;
    
    const params = new URLSearchParams({
      type:"non_provisional",
      abstract: content,
      section:title
    }).toString();
    
    requestMessage.addData
    (
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_landingpage2/provisional_specifications/rewrite_specification?${params}`
    );

    requestMessage.addData
    (
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData
    (
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  promptApiCall=async (index:number)=>
  {
    this.setState({disableprompt:true})
    const selectied=this.state.selection;
    const prompts=this.state.sections[index].prompt;
    const title=this .state.sections[index].title 
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apipromptCallID = requestMessage.messageId;
    
    const params = new URLSearchParams(
    {
      type:title,
      abstract: selectied,
      prompt: prompts,
    }
  ).toString();

    requestMessage.addData
    (
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.apipromptEndPoint}?${params}`
    );

    requestMessage.addData
    (
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData
    (
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getProvisionalDetails = async () => {
    const header = {
      token: await getStorageData("token"),
    };

    const userstring=this.state.abstract
    const specification_type='non_provisional'
    const params = new URLSearchParams({
      abstract: userstring,
      specification_type: specification_type,
    }).toString();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiProvisonalDetailAPIEndPoint = requestMessage.messageId;

    requestMessage.addData
    (
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_landingpage2/provisional_specifications/generate_provisional_specification?${params}`
    );

    requestMessage.addData
    (
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData
    (
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  

  handleContentChange(value: string, targetIndex: number) {
    this.setState(prevState => {
      const updatedNewSections = prevState.sections.map((section, index) => ({
        ...section,
        content: index === targetIndex ? value : section.content // Update specific section content
      }));

      // Record the change in the undostack for the specific index
      const undoStack = [
        ...prevState.undoStack,
        prevState.sections.map((section, index) => ({
          index,
          content: section.content
        }))
      ];

      return {
        sections: updatedNewSections,
        undoStack,
        redoStack:[], 
      };
    });
  }

  handleUndo(index: number) {
    this.setState(prevState => {
      if (prevState.undoStack.length === 0) return null; // No action to undo

      const lastStates = prevState.undoStack.pop(); // Get the laststate from undostack

      if (!lastStates) return null; // If no laststate, exit early

      const previousContent = lastStates.find(change => change.index === index)?.content;

      // Push the currentstate to the redostack
      const redoStack = [
        ...prevState.redoStack,
        prevState.sections.map((section, idex) => ({
          index: idex,
          content: section.content
        }))
      ];

      if (previousContent !== undefined) {
        const updatedNewSections = prevState.sections.map((section, idex) => ({
          ...section,
          content: idex === index ? previousContent : section.content
        }));

        return {
          sections: updatedNewSections,
          undoStack: prevState.undoStack,
          redoStack
        };
      }
      return null;
    });
  }

  handleRedo(index: number) {
    this.setState(prevState => {
      if (prevState.redoStack.length === 0) return null; // No action to redo

      const nextState = prevState.redoStack[prevState.redoStack.length - 1]; // Peek at the nextstate

      if (!nextState) return null; // If no nextstate, exit early

      const nextContent = nextState.find(change => change.index === index)?.content;

      const undoStack = [
        ...prevState.undoStack,
        prevState.sections.map((section, idex) => ({
          index: idex,
          content: section.content
        }))
      ];
      
      if (nextContent !== undefined) {
        const updatedNewSections = prevState.sections.map((section, idex) => ({
          ...section,
          content: idex === index ? nextContent : section.content
        }));
        // Update redostack by removing the usedstate
        const newRedoStack = prevState.redoStack.slice(0, prevState.redoStack.length - 1);

        return {
          sections: updatedNewSections,
          undoStack,
          redoStack: newRedoStack
        };
      }
      return null;
    });
  }


handelSave=async()=>{

const header = {
  token: await getStorageData("token"),
};

const formData = new FormData();

let nonprrovisonalid = await getStorageData("nonprrovisonalid")
setStorageData("Specid",nonprrovisonalid )

formData.append("id",nonprrovisonalid);
//First index Title->invention
let typesend=this.state.sections[0].title 
let contentsend=this.state.sections[0].content 
formData.append("provisional[field_of_invention]", typesend);
formData.append("provisional[field_of_invention_specification]", contentsend);

//second index Backround
contentsend=this.state.sections[1].content 
typesend=this.state.sections[1].title 
formData.append("provisional[background_of_invention]", typesend);
formData.append("provisional[background_of_invention_specification]", contentsend);

//third index  summary
contentsend=this.state.sections[2].content 
typesend=this.state.sections[2].title 
formData.append("provisional[summary_of_invention]", typesend);
formData.append("provisional[summary_of_invention_specification]", contentsend);

//fourth index objective
typesend=this.state.sections[3].title 
contentsend=this.state.sections[3].content 
formData.append("provisional[detailed_description_of_invention]", typesend);
formData.append("provisional[detailed_description_of_invention_specification]", contentsend);

// fifth   index ->claim
contentsend=this.state.sections[4].content 
typesend=this.state.sections[4].title 
formData.append("provisional[claims]", typesend);
formData.append("provisional[claims_specifications]", contentsend);

//sith index abstract
contentsend=this.state.sections[5].content 
typesend=this.state.sections[5].title 
formData.append("provisional[abstract]", typesend);
formData.append("provisional[abstract_specifications]", contentsend);

const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

this.apiSaveProvsonalCallID = requestMessage.messageId;

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  `bx_block_landingpage2/provisional_specifications` 
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestBodyMessage),
  formData
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.exampleAPiMethod
);

runEngine.sendMessage(requestMessage.id, requestMessage);

}

goingToSpecificationPage = async () => {
  this.setState({loading:true})
    await this.handelSave();
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "SpecificationPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props); 
    this.send(message);
};

  // Customizable Area End
}
// Customizable Area Start

// Customizable Area End