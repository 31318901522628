import { Box, styled } from '@material-ui/core';
import { getStorageData } from '../../framework/src/Utilities';
import React, { Component } from 'react';
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions.web";


interface MyComponentProps {
    // Add props here
    title?: string
}

// Define the state interface
interface MyComponentState {
    // Add state here
    footerDetails: {
        contactEmail: string;
        contactPhone: string;
        contactAddress: string;
        copyright:string;
    }
    openTermAndConditionModel: boolean,
    modelType: string,
    termsDoc: string,
    termsAndCondition: boolean,
    formValues: {
        rememberMe: boolean | undefined;
        email: string;
        password: string;
        terms: boolean; 
      },
      formErrors: {
        email: string;
        password: string;
        terms: string;
      },
   
}

class Footer extends Component<MyComponentProps, MyComponentState> {
    // Initialize state
    constructor(props: MyComponentProps) {
        super(props);
        this.state = {
            // Add state here
            footerDetails: {
                contactEmail: "",
                contactPhone: "",
                contactAddress: "",
                copyright:"",
            },
            openTermAndConditionModel: false,
            modelType: "",
            termsDoc: "",
            termsAndCondition: false,
            formValues: {
                email: "",
                password: "",
                terms: false,
                rememberMe: undefined
              },
              formErrors: {
                email: "",
                password: "",
                terms: ""
              },
        };
    }

    async componentDidMount() {
        const storageData = await getStorageData("contactData")
        const parseData = JSON.parse(storageData);
        this.setState({
            footerDetails: parseData
        });
    }
    validateTerms = (check: boolean) => {
        if (!check) {
          return 'Please accept Privacy Policy and Terms & Conditions to proceed';
        } else {
          return '';
        }
      };
    
    hendleOpenTAndCModel = (type: string) => {
        if(this.state.termsDoc && type === "Terms") {
          const link = document.createElement('a');
        link.href = this.state.termsDoc;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(this.state.termsDoc);
        } else {
          this.setState({
            openTermAndConditionModel: true,
            modelType: type
          });
        }
      };
      handleChangeTerms = (event:any) => {
        const { checked } = event.target;
        
          this.setState((prevState) => ({
       
            termsAndCondition: !this.state.termsAndCondition,
            formValues: {
              ...prevState.formValues,
              terms: checked
            },
            formErrors: {
              ...prevState.formErrors,
              terms: this.validateTerms(checked)
            }
          }));
      };
      getTermDoc = (document: string) => {
        this.setState({
          termsDoc: document
        })
      }
      
      handleCloseTAndCModel = () => {
        this.setState({ openTermAndConditionModel: false })
      };
      

    render() {
        return (
            <FooterCss>
                <Box className='footerFirstContainer'>
                    <Box className='footer-main-box'>
                        <Box className='footer-logo-box'>
                            <img className='footerLogoStyle' src={require("./headerLogo.svg")} alt='headerLogo' />
                        </Box>
                        <Box className='footer-containt-box'>
                            <Box className='footer-heading-Text'>
                                Contact us
                            </Box>
                            <Box className='footer-text'>
                                {this.state.footerDetails?.contactPhone}
                            </Box>
                        </Box>
                        <Box className='footer-containt-box'>
                            <Box className='footer-heading-Text'>
                                Email
                            </Box>
                            <Box className='footer-text'>
                                {this.state.footerDetails?.contactEmail}
                            </Box>
                        </Box>
                        <Box className='footer-containt-box'>
                            <Box className='footer-heading-Text'>
                                Address
                            </Box>
                            <Box className='footer-text'>
                                {this.state.footerDetails?.contactAddress}
                            </Box>
                        </Box>
                    </Box>
                    <Box className='footer-divider'></Box>
                    <Box className='footer-copyRight-box'>
                        <Box className='footer-copy-text'>© {this.state.footerDetails?.copyright}</Box>
                        <Box  component="span" className='footer-privacy-text LogInCreateAcc-dif'
                         id="privacy-model-btn" 
                         onClick={this.hendleOpenTAndCModel.bind(this,"Privacy")}>Privacy</Box>
                    </Box>
                </Box>
                <TermsConditions
                  navigation={undefined}
                  id={"TermsConditions"}
                  getTermDoc={this.getTermDoc}
                  openTermAndConditionModel={this.state.openTermAndConditionModel}
                  modelType={this.state.modelType}
                  formValTerms={this.state.termsAndCondition}
                  handleChangeTerms={this.handleChangeTerms}
                  hendleOpenTAndCModel={this.hendleOpenTAndCModel}
                  handleCloseTAndCModel={this.handleCloseTAndCModel}
                />
            </FooterCss>
        );
    }
}

const FooterCss = styled(Box)({
    marginTop: 'auto',
    '& .footerFirstContainer': {
        background: '#E8E7E7',
        padding: '20px 108px 20px',
        "@media(max-width:1280px)": {
            padding: "40px 40px",
        },
        "@media(max-width:960px)": {
            padding: "40px 20px",
        },
        "@media(max-width:600px)": {
            padding: "40px 20px",
        },
    },
    "& .footer-main-box": {
        display: 'flex',
        justifyContent: "space-between",
        "@media(max-width:900px)": {
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: "20px",
        },
    },
    "& .footer-logo-box": {
        display: "flex",
        alignItems: "center"
    },
    "& .footerLogoStyle": {
        display: "flex",
        width: "200px",
        height: "29px",
        padding: "0.271px 5px",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    "& .footer-heading-Text": {
        color: "#2B2E2F",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        textTransform: "uppercase",
        marginBottom: 8
    },
    "& .footer-text": {
        color: "#484C4E",
        fontFamily: "Inter",
        fontSize: "18px",
        fontWeight: 700
    },
    "& .br-tag": {
        marginBottom: 12
    },
    "& .footer-divider": {
        width: "100%",
        height: 1,
        background: "#94A3B8",
        margin: "9px 0 24px 0"
    },
    "& .footer-copyRight-box": {
        display: "flex",
        justifyContent: "space-between"
    },
    "& .footer-copy-text": {
        color: "#212324",
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 400
    },
    "& .footer-privacy-text": {
        color: "#DF4833",
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 400
    },
})
export default Footer;