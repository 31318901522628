export const profileImg = require("../assets/profileImg.svg");
export const profileEdit = require("../assets/profileEdit.svg");
export const showEye = require("../assets/show-eye.svg");
export const hideEye = require("../assets/hide-eye.svg");
export const logout = require("../assets/logout.svg");
export const deleteIcon = require("../assets/delete.svg");
export const success = require("../assets/success.svg");
export const closeIcon = require("../assets/closeIcon.svg");
export const uploadIcon = require("../assets/uploadIcon.svg");
export const removeIcon = require("../assets/removeIcon.svg");
export const tooltip = require("../assets/tooltip.svg");
export const recentSearch = require("../assets/recentSearch.png");
export const searchIcon = require("../assets/searchIcon.svg");
export const messageIcon = require("../assets/messageIcon.svg");
export const downloadIcon = require("../assets/downloadIcon.svg");
export const menuIcon = require("../assets/menuIcon.svg");
export const searchBlackIcon = require("../assets/searchBlackIcon.svg");
export const documentIcon = require("../assets/document.svg")
