import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

interface PublicationInfo {
  summary: string;
};

interface InlineLinks {
  serpapi_cite_link: string;
  cited_by: {
    total: number;
    link: string;
    cites_id: string;
    serpapi_scholar_link: string;
  };
  related_pages_link: string;
  serpapi_related_pages_link: string;
  versions: {
    total: number;
    link: string;
    cluster_id: string;
    serpapi_scholar_link: string;
  };
};

interface Publication {
  position: number | null,
  rank: number | null,
  scholar_id: string,
  serpapi_link: string,
  is_scholar: boolean,
  title: string,
  snippet: string,
  url_hostname: string,
  author: string,
  publication_date: string
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  gridView: boolean;
  publicationSerpData: Publication[];
  seTselectedPublicationNumbers:any
  clearTrigger:boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedPublicationNumbers: string[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PublicationPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
    ];

    this.state = {
      selectedPublicationNumbers: [],
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidUpdate(prevProps: Props) {
    if (prevProps.clearTrigger !== this.props.clearTrigger) {
      this.setState({ selectedPublicationNumbers: [] });
    }
  }
  onChangePublicationselect = (publicationNumber: string) => {
    this.setState((prevState) => {
      const isAlreadySelected = prevState.selectedPublicationNumbers.includes(publicationNumber);
      const updatedSelection = isAlreadySelected
        ? prevState.selectedPublicationNumbers.filter((num) => num !== publicationNumber)
        : [...prevState.selectedPublicationNumbers, publicationNumber];
        this.props.seTselectedPublicationNumbers(updatedSelection);
      return { selectedPublicationNumbers: updatedSelection };
    });
  };
  // Customizable Area End
}
