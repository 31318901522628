import React from "react";
// Customizable Area Start
import { Box, Dialog, Grid, IconButton, TextField, styled, DialogTitle, DialogActions } from "@material-ui/core";
import Header from "../../../components/src/Header.web";
import UserProfilesAccountController, { Props } from "./UserProfilesAccountController.web";
import { hideEye, logout, showEye, deleteIcon, success, closeIcon } from "./assets";
import Loader from "../../../blocks/landingpage/src/Loader.web";
// Customizable Area End

export default class UserProfilesAccount extends UserProfilesAccountController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { formValues, formErrors } = this.state;
    return (
      <>
        <Loader loading={this.state.loading} />
        <Header title={""} />
        <UserProfileAccountStyle>
          <Grid container className="gapping">
            <Grid container item xs={12} md={6} className="account-main">
              <Grid item xs={12}>
                <Box className="heading-account" id="userProfile-heading">Account</Box>
              </Grid>
              <Grid item xs={12}>
                <Box className="sub-heading">Change Password</Box>
              </Grid>
              <Grid item xs={12}>
                <Box className="profileTextFields">
                  <Box className="profileTextFieldsLabelBox">
                    <Box className="lableInput">Current Password</Box>
                  </Box>
                  <Box className="main-field">
                    <TextField
                      className="FormTextField"
                      placeholder="Enter current password"
                      variant="outlined"
                      id="currentPassword"
                      type={this.state.changeIcon ? "text" : "password"}
                      value={formValues.currentPassword}
                      onChange={this.handleChangeCurrentPassword}
                      error={Boolean(formErrors.currentPassword)}
                      helperText={formErrors.currentPassword}
                      name="currentPassword"
                      fullWidth
                      required
                      inputProps={{
                        className: "FormTextField-placeholder"
                      }}
                    />
                    <IconButton className="icon-eyes" id="current-icon-eyes" onClick={() => this.onClickShowChangePasswordIcon()}>
                      {!this.state.changeIcon ? (
                        <img src={hideEye} alt="hideEye" />
                      ) : (
                        <img src={showEye} alt="showEye" />
                      )}
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className="profileTextFields">
                  <Box className="profileTextFieldsLabelBox">
                    <Box className="lableInput">New Password</Box>
                  </Box>
                  <Box className="main-field">
                    <TextField
                      className="FormTextField"
                      placeholder="Enter New password"
                      variant="outlined"
                      type={this.state.changeNewIcon ? "text" : "password"}
                      name="newPassword"
                      id="newPassword"
                      fullWidth
                      required
                      value={formValues.newPassword}
                      onChange={this.handleChangeNewPassword}
                      error={Boolean(formErrors.newPassword)}
                      helperText={formErrors.newPassword}
                      inputProps={{
                        className: "FormTextField-placeholder"
                      }}
                    />
                    <IconButton className="icon-eyes" id="new-icon-eyes" onClick={() => this.onClickShowNewPasswordIcon()}>
                      {!this.state.changeNewIcon ? (
                        <img src={hideEye} alt="hideEye" />
                      ) : (
                        <img src={showEye} alt="showEye" />
                      )}
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className="profileTextFields">
                  <Box className="profileTextFieldsLabelBox">
                    <Box className="lableInput">Confirm Password</Box>
                  </Box>
                  <Box className="main-field">
                    <TextField
                      className="FormTextField"
                      placeholder="Enter confirm password"
                      variant="outlined"
                      type={this.state.changeConfirmIcon ? "text" : "password"}
                      name="confirmPassword"
                      id="confirmPassword"
                      fullWidth
                      required
                      value={formValues.confirmPassword}
                      onChange={this.handleChangeConfirmPassword}
                      error={Boolean(formErrors.confirmPassword)}
                      helperText={formErrors.confirmPassword}
                      inputProps={{
                        className: "FormTextField-placeholder"
                      }}
                    />
                    <IconButton className="icon-eyes" id="confirm-icon-eyes" onClick={() => this.onClickShowConfirmPasswordIcon()}>
                      {!this.state.changeConfirmIcon ? (
                        <img src={hideEye} alt="hideEye" />
                      ) : (
                        <img src={showEye} alt="showEye" />
                      )}
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <button className="profileSaveBtn" id="save-btn" onClick={this.onClickSaveChanges}>
                  Save Changes
                </button>
              </Grid>
              <Grid item xs={12}>
                <Box className="divider"></Box>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Box className="sub-heading" id="subHeading">Account Action</Box>
                </Grid>
                <Grid item xs={6} className="buttonMainGrid">
                  <Box className="button-container">
                    <Box className="logout-delete" id="logOut" onClick={() => this.handlePopUpModel("logOut")}>
                      <img src={logout} alt="logout" />
                      <Box className="lableInput">Log out</Box>
                    </Box>
                    <Box className="logout-delete" id="delete-Acc" onClick={() => this.handlePopUpModel("delete")}>
                      <img src={deleteIcon} alt="logout" />
                      <Box className="lableInput">Delete</Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <SuccessDialog
            open={this.state.openModel}
            id="onClose-model"
            onClose={this.handleModelClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              className: "delete-modal",
              elevation:0
            }}
            BackdropProps={{
              className: "backdrop-modal-delete",
            }}
          >
            <Box className="closeIconStyle" id="model-close" onClick={this.handleModelClose}>
              <IconButton aria-label="close">
                <img src={closeIcon} height={24} width={24} alt="closeIcon" />
              </IconButton>
            </Box>
            <Box className="dailog-Box">
              <img src={success} height={80} width={80} alt="success" />
              <DialogTitle className="title-model" id="alert-dialog-title">{"Your Password has been successfully Updated"}</DialogTitle>
            </Box>
          </SuccessDialog>
          <DeleteAccountDialog
          open={this.state.popupOpen}
          onClose={this.handlePopupClose}
          id="terms-onClose-model"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            className: "delete-modal",
            elevation: 0
          }}
          BackdropProps={{
            className: "backdrop-modal-delete",
          }}
        >
          <Box className="closeIconStyle">
            <IconButton aria-label="close">
              <img src={closeIcon} height={24} width={24} alt="closeIcon" id="closeIcon" onClick={() => this.handlePopupClose()} />
            </IconButton>
          </Box>
          <DialogTitle className="title-model-delete" id="delete-dialog-title">{this.state.popUpType === "delete" ? `Are you sure you want to delete the account?` : `Are you sure you want to logout?`}</DialogTitle>
          <DialogActions>
            <Grid container spacing={3} justifyContent="flex-end">
              <Grid item className="button-conainer">
                <button className="cancleBtn" id="cancleBtn" onClick={() => this.handlePopupClose()}>
                  Cancel
                </button>
              </Grid>
              <Grid item className="button-conainer">
                <button className={"deleteBtn"} id="confirmBtn" onClick={() => this.handleDeleteAndLogOut()}>
                {this.state.popUpType === "delete" ?  `Delete` : `Log Out`}
                </button>
              </Grid>
            </Grid>
          </DialogActions>
        </DeleteAccountDialog>
        </UserProfileAccountStyle>
      </>
      // Customizable Area End
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const UserProfileAccountStyle = styled(Box)({
  padding: '2% 5%',
  backgroundColor: "#E8E7E7",
  "& .gapping":{
    gap:30
  },
  "& .account-main": {
    display: "flex",
    gap: "30px",
  },
  "& .profileTextFields": {
    display: "flex",
    gap: "8px",
    flexDirection: 'column'
  },
  "& .profileTextFieldsLabelBox": {
    display: "flex"
  },
  "& .lableInput": {
    color: "#4F5356",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
  },
  "& .FormTextField": {
    backgroundColor: "white",
    borderRadius: "10px",
    "& .MuiOutlinedInput-notchedOutline":{
      borderRadius: "10px",
      top: -6
    },
    '& .Mui-focused fieldset': {
      borderColor: '#4F5356',
    },
    "& .MuiFormHelperText-root.Mui-error": {
      fontFamily: "Poppins",
      fontWeight: 500,
      background: "#E8E7E7",
      color: "#FF1938"
    },
    "& .MuiFormHelperText-contained": {
      margin: 0
    }
  },
  "& .FormTextField-placeholder": {
    "&::placeholder": {
      color: '#AEB0B1',
      fontFamily: "Inter",
      fontWeight: 400,
      opacity: 1,
      fontSize: 16
    },
  },
  "& .main-field": {
    position: "relative",
    "& .icon-eyes": {
      position: "absolute",
      top: 5,
      right: 5
    }
  },
  "& .profileSaveBtn": {
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    borderRadius: "8px",
    background: "#DF4833",
    textTransform: "none",
    width: "160px",
    height: "44px",
    padding: "10px 16px",
    gap: "8px",
    cursor: "pointer",
    border: 'none'
  },
  "& .divider": {
    width: "100%",
    height: "1px",
    background: '#CBD5E1'
  },
  "& .logout-delete": {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: 5,
    width: 'max-content',
  },
  "& .button-container": {
    display: "flex",
    flexDirection: 'column',
    gap: 15
  },
  "& .heading-account": {
    color: "#4F5356",
    fontFamily: "Poppins",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "40px",
  },
  "& .sub-heading": {
    color: "#4F5356",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "40px",
  },
  "& .buttonMainGrid": {
    display: "flex",
    justifyContent: "end"
  },
});

const SuccessDialog = styled(Dialog)({
  "& .delete-modal": {
    background: '#F5F5F4',
    padding: "40px 60px",
    borderRadius: 0,
    "@media (max-width: 760px)": {
      padding: "40px 30px",
    },
    "@media (max-width: 450px)": {
      padding: "40px 10px",
    },
  },
  "& .backdrop-modal-delete":{
    backgroundColor:'rgba(15, 23, 42, 0.4)'
  },
  "& .title-model":{
    '& .MuiTypography-h6':{
      color: '#4F5356',
      fontFamily: 'Poppins',
      fontSize: 22,
      fontWeight: 600,
      "@media (max-width: 760px)": {
        textAlign:"center"
      },
    }
  },
  "& .dailog-Box": {
    display: "flex",
    alignItems: 'center',
    flexDirection: 'column',
    gap: 30
  },
  "& .closeIconStyle": {
    position: 'absolute',
    top: 0,
    right: 0
  },
  
})

const DeleteAccountDialog = styled(Dialog)({
  "& .MuiDialogTitle-root": {
    borderBottom: "1px solid #CBD5E1",
    padding: "70px 40px",
  },
  "& .MuiDialogActions-root": {
    borderTop: "1px solid #CBD5E1",
    padding: "24px",
  },
  "& .delete-modal": {
    borderRadius: 8,
    maxWidth: 600,
    background: '#F5F5F4',
  },
  "& .backdrop-modal-delete": {
    backgroundColor: 'rgba(15, 23, 42, 0.4)'
  },
  "& .closeIconStyle": {
    top: 0,
    right: 0,
    position: 'absolute',
  },
  "& .button-conainer": {
    "@media (max-width: 435px)": {
      width:"100%"
    },
  },
  "& .deleteBtn": {
    fontWeight: 700,
    fontStyle: "normal",
    color: "#FFF",
    width: 150,
    borderRadius: "8px",
    fontFamily: "Inter",
    height: 56,
    background: "#EF4444",
    gap: "8px",
    padding: "10px 16px",
    textTransform: "none",
    cursor: "pointer",
    fontSize: "16px",
    border: 'none',
    "@media (max-width: 435px)": {
      width:"100%"
    },
  },
  "& .cancleBtn": {
    color: "#DF4833",
    fontSize: "16px",
    fontFamily: "Inter",
    border: 'none',
    fontWeight: 700,
    textTransform: "none",
    height: 56,
    cursor: "pointer",
    width: 150,
    fontStyle: "normal",
    padding: "10px 16px",
    borderRadius: "8px",
    background: "#FCEDEB",
    gap: "8px",
    "@media (max-width: 435px)": {
      width:"100%"
    },
  },
  "& .title-model-delete":{
    '& .MuiTypography-h6':{
      fontFamily: 'Poppins',
      color: '#4F5356',
      fontWeight: 500,
      fontSize: 22,
      "@media (max-width: 760px)": {
        textAlign:"center"
      },
    }
  },
})

// Customizable Area End
