import React from 'react';
// Customizable Area Start

import { Box, Button, TextField, IconButton } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';  
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckIcon from '@mui/icons-material/Check'; 
import TitleIcon from '@mui/icons-material/Title';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ProvisionalSpecificationPageController,{Props} from "./ProvisionalSpecificationPageController.web";
import Loader from "./Loader.web";
import { styled } from '@material-ui/core';

// Customizable Area End


// Customizable Area Start
// Define a section's structure
export interface Section {
  title: string;
  subtitle: string;
  index: number;
  content: string;
  prompt:string;
}

// Custom toolbar configuration
const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'link'],
    [{ 'undo': 'undo' }, { 'redo': 'redo' }]
  ],
};



// Customizable Area End
export default class ProvisionalSpecificationPage extends ProvisionalSpecificationPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleUndo = this.handleUndo.bind(this);
    this.handleRedo = this.handleRedo.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <PageCss>
       <>
            <Loader loading={this.state.loading} />
              {!this.state.errors ? (
                <Box key={'Allsection'} sx={{
                  padding: '24px',
                  display: 'grid',
                  gridTemplateColumns: '1fr',
                }}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px',gap:"5px",fontFamily: "Inter", }}>
                      <Button
                        id="view-specification-button"
                        data-testid="view-specification-button"
                        sx={{
                          backgroundColor: '#f44336',
                          color: '#fff',
                          textTransform: 'none',
                          fontFamily: "Inter",
                          '&:hover': {
                            backgroundColor: '#f44336',
                          },
                        }}
                        startIcon={<CalendarMonthIcon />}
                        onClick={() => this.goingToSpecificationPageReport()}
                      >
                        View Specification
                      </Button>
  
                    <Button
                        sx={{
                          backgroundColor: '#f44336',
                          color: '#fff',
                          textTransform: 'none',
                          '&:hover': {
                            backgroundColor: '#f44336',
                          },
                        }}
                        className='Save'
                        data-testid={`save-button`}
                        id={`save-button`}
                      onClick={() => this.handelSave()}
                      startIcon={<CheckIcon />}
                    >
                      Save
                    </Button>
                  </Box>
  
                  <Box>
                    {this.state.sections.map((section: Section, index: number) => (
                      <div 
                        key={`section-${section.index}`}
                        className={`section-quill-${section.index}`}
                        onMouseUp={this.handleMouseUp}
                        style={{
                          position: 'sticky',
                          backgroundColor: '#F5F5F4',
                          fontFamily: "Inter",
                          top: `${(section.index) * 69}px`,
                          border: '1px solid #ddd',
                          padding: '24px',
                          paddingTop: '12px',
                          borderRadius: '10px',
                          marginBottom: '32px'
                        }}
                        >
                        <Box>
                          <h3 id={`section-title-${section.index}`} style={{ fontWeight: 'bold', margin: '8px',    fontFamily: "Inter", }}>{section.title}</h3>
                          <h5 id={`section-subtitle-${section.index}`} style={{ fontWeight: 'normal', color: '#474747', margin: '8px',    fontFamily: "Inter", }}>{section.subtitle}</h5>
                          <Box sx={{ position: 'relative', marginBottom: '16px' }}>
                            <ReactQuill
                              theme="snow"
                              value={section.content}
                              onChange={(e) => this.handleContentChange(e, index)}
                              modules={modules}
                              style={{ backgroundColor: '#fff', height: '150px', borderRadius: '10px', paddingBottom:'10px',   fontFamily: "Inter",}}
                              data-testid={`quill-editor-${section.index}`}
                              id={`quill-editor-${section.index}`}
                            />
  
                            <Box sx={{ position: 'absolute', top: '5px', right: '10px', display: 'flex', gap: '5px',    fontFamily: "Inter", }}>
                              <IconButton id={`undo-button-${section.index}`} aria-label="undo" onClick={() => this.handleUndo(index)}>
                                <UndoIcon />
                              </IconButton>
                              <IconButton id={`redo-button-${section.index}`} aria-label="redo" onClick={() => this.handleRedo(index)}>
                                <RedoIcon />
                              </IconButton>
                            </Box>
                          </Box>                      
                          <CustomTextField
                            className={`prompt-${index}`}
                            placeholder="Summary of the invention"
                            fullWidth
                            variant="outlined"
                            id={`prompt-${section.index}`}
                            data-testid={`prompt-${section.index}`}
                            value={section.prompt}            
                            onChange={(e) => this.handlePromptChange(e, index)}
                            multiline
                            InputProps={{
                              startAdornment: <SearchIcon sx={{ marginRight: '8px' }} style={{ color: 'black' }} />,
                              endAdornment: (
                                <IconButton
                                  className={`promptcall-${index}`}
                                  id={`promptcall-${section.index}`}
                                  data-testid={`promptcall-${section.index}`}
                                  onClick={() => this.promptApiCall(index)}
                                  disabled={this.state.disableprompt}
                                >
                                  <ArrowForwardIosIcon />
                                </IconButton>
                              ),
                            }}
                          />
                          <Box sx={{ display: 'flex', gap: '8px', padding: '8px', borderRadius: '8px',   fontFamily: "Inter", }}>
                            <Button
                              onClick={() => this.Replace(index)}
                              id={`replace-${section.index}`}
                              data-testid={`replace-${section.index}`}
                              sx={{
                                border: '1px solid #ddd',
                                color: '#474747',
                                textTransform: 'none',
                                borderRadius: '8.5px',
                                paddingLeft: '10px',
                                gap:"2px",                                    
                                fontFamily: "Inter",
                              }}>
                              <TitleIcon /> Replace
                            </Button>
                            <Button
                              disabled={this.state.disablerewrite}
                              onClick={() => this.Rewrite(index)}
                              id={`writeAI-${section.index}`}
                              data-testid={`writeAI-${section.index}`}
                              sx={{
                                border: '1px solid #ddd',
                                color: '#474747',
                                textTransform: 'none',
                                borderRadius: '8.5px',
                                paddingLeft: '10px',
                                gap:"2px",
                                fontFamily: "Inter",
                              }}>
                              <TitleIcon /> Rewrite
                            </Button>
                          </Box>
                        </Box>
                      </div>
                    ))}
                  </Box>
                </Box>
              ) : (
                <Box component="div" className="no-result-text">No Results Found</Box>
              )}         
</>
      </PageCss>
    );
  }
  
}

// Customizable Area Start

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '10px',
  marginBottom: '16px',
  overflow: 'auto',
  height: '100px',
  fontFamily: 'Inter',
  '& .MuiInputBase-root': {
    color: '#6c757d',
    paddingTop:'0px',
  },
  '& .MuiIconButton-root': {
    color: '#f44336',
  },
  '& .MuiInputBase-input': {
    color: '#000', // Change text color to black
  },
}));

const PageCss = styled(Box)({
    "& .classification-white-container": {
      padding: "50px 108px",
      background: "#E8E7E7",
      fontFamily: "Inter",
      "@media(max-width:1050px)": {
        padding: "40px 40px",
      },
      "@media(max-width:920px)": {
        padding: "40px 20px",
      },
    },
    "& .back-arrow-box": {
      gap: 16,
      display: "flex",
      alignItems: "center",
      marginBottom: "24px",
      fontFamily: "Inter",
    },
    "& .back-arrow-img": {
      cursor: "pointer"
    },
    "& .back-arrow-text": {
      fontSize: "18px",
      fontFamily: "Inter",
      color: "#212324",
      fontWeight: 400,
    },
    "& .no-result-text": {
    fontWeight: 700,
    fontSize: 24,
    fontFamily: "Inter",
    justifyContent: "center",
    height: "200px",
    display: "flex",
    alignItems: "center"
  },
  });

// Customizable Area End
