import React from "react";
// Customizable Area Start
import { Box, Checkbox, Grid, styled } from "@material-ui/core";
import { blueCheckedIcon, document, uncheckedIcon, whiteCalender } from "./assets";
import { Link } from "react-router-dom"

interface ProductDetails {
  position: number,
  title: string,
  link: string,
  product_link: string,
  product_id: string,
  serpapi_product_api: string,
  number_of_comparisons: string,
  comparison_link: string,
  serpapi_product_api_comparisons: string,
  source: string,
  price: string,
  extracted_price: number,
  rating: number,
  reviews: number,
  extensions: string[],
  thumbnail: string,
  tag: string,
  delivery: string
}
// Customizable Area End

import ProductLandingPageController, {
  Props,
} from "./ProductLandingPageController";

export default class ProductLandingPage extends ProductLandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    
    return (
      <>
        <ProductLandingPageContainerCss>
          <Grid container spacing={3}>
            {this.props.productDetails?.map((item:ProductDetails,index) => (
              <Grid item key={index} xs={this.props.gridView ? 6 : 12}>
                <Box className={this.props.gridView ? "product-white-container-gridView" : "product-white-container"}>
                <Box className="product-main-text">
                <Checkbox
                  checked={this.state.selectedProductNumbers.includes(item.product_id)}
                  onChange={() => this.onChangePublicationselect(item.product_id)}
                  data-test-id="changeCheckBoxDataTestId"
                  checkedIcon={
                    <img
                      src={blueCheckedIcon}
                      alt="barcodeIp"
                    />
                  } icon={
                    <img
                      src={uncheckedIcon}
                      alt="barcodeIp"
                    />
                  } color="primary" className="logIn-checkbox-label" />
                    
                    {item?.title}</Box>
                  <Box className={this.props.gridView ? "product-img-main-gridView" : "product-img-main"}>
                      <Box className="img-loop">
                        <img src={item?.thumbnail} height={172} width={166} alt="keyFeatureImg" />
                      </Box>
                  </Box>
                  <Box className={this.props.gridView ? "product-Id-btn-gridView" : "product-Id-btn"}>
                    <Box className={this.props.gridView ? "product-Id-btn-text-gridview" : "product-Id-btn-text"}>
                      {item?.product_id}
                    </Box>
                  </Box>
                  <Box className="phones-name" component={"ul"}>
                    {item?.extensions?.map((name: string) => (
                      <Box className="product-li-text" component={"li"}>
                      {name}
                    </Box>
                    ))}
                  </Box>
                  <Box>
                    <Box className="product-text-bold" id="heading">
                      Description
                    </Box>
                    <Box className="product-p-text">
                      5G goes Pro, A14 Bionic rockets past every other smartphone chip. The iphone 12 Pro features a 6.1’ Super Retina XDR Display, LiDAR scanner for ultrafast and accurate depth maps of whatever space you’re in and magsafe wireless charging. The pro camera system takes low-light
                    </Box>
                  </Box>
                  <Box className="product-buttons-main-box">
                    <Box className="product-buttons-box">
                      <img src={document} alt="document" />
                      <Box className="product-button-text">
                        Similar Products
                      </Box>
                    </Box>
                    <Link to={`productdetail/${item?.product_id}`} className="a-class">
                    <Box id="navigate-page" className="product-buttons-box-red">
                      <img src={whiteCalender} alt="whiteCalender" />
                      <Box className="product-button-text-white">
                        View More
                      </Box>
                    </Box>
                    </Link>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </ProductLandingPageContainerCss>
      </>
    );
  }
}

// Customizable Area Start
const ProductLandingPageContainerCss = styled(Box)({
  "& .product-white-container": {
    background: "#F5F5F4",
    borderRadius: 8,
    maxHeight: "878px",
    overflowY: "scroll",
    padding: "32px 24px",
  },
  "& .a-class": {
    textDecoration: "none"
  },
  "& .product-white-container-gridView": {
    background: "#F5F5F4",
    borderRadius: 8,
    maxHeight: "939px",
    overflowY: "scroll",
    padding: "32px 16px",
  },
  "& .product-main-text": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "18px",
    marginBottom: "24px"
  },
  "& .box-span": {
    color: "#DF4833"
  },
  "& .product-img-main": {
    background: "#F0F0F0",
    padding: "24px",
    gap: 24,
    flexWrap: "wrap",
    display: "flex",
    height: "172px"
  },
  "& .product-img-main-gridView": {
    gap: 24,
    flexWrap: "wrap",
    display: "flex",
    padding: "0 10px",
  },
  "& .img-loop": {
    height: 172,
    width: 166,
    objectFit: "contain"
  },
  "& .product-Id-btn": {
    width: "fit-content",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: "58px",
    background: "#FCEDEB",
    padding: "0px 24px",
    borderRadius: 8,
    marginTop: "24px",
    "@media(max-width:480px)": {
      padding: "0px 14px",
    },
    "@media(max-width:380px)": {
      padding: "0px 10px",
    },
  },
  "& .product-Id-btn-text": {
    color: "#DF4833",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: 15,
    textTransform: "none",
    cursor: "pointer",
    "@media(max-width:550px)": {
      fontSize: 20,
    },
    "@media(max-width:460px)": {
      fontSize: 16,
    },
    "@media(max-width:380px)": {
      fontSize: 14,
    },
  },
  "& .product-Id-btn-gridView": {
    width: "fit-content",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: "58px",
    background: "#FCEDEB",
    padding: "0px 24px",
    borderRadius: 8,
    marginTop: "24px",
    "@media(max-width:900px)": {
      padding: "0px 14px",
    },
    "@media(max-width:790px)": {
      padding: "0px 10px",
    },
    "@media(max-width:710px)": {
      padding: "0px 5px",
    },
  },
  "& .product-Id-btn-text-gridView": {
    color: "#DF4833",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: 24,
    textTransform: "none",
    cursor: "pointer",
    "@media(max-width:1090px)": {
      fontSize: 20,
    },
    "@media(max-width:900px)": {
      fontSize: 18,
    },
    "@media(max-width:790px)": {
      fontSize: 16,
    },
    "@media(max-width:710px)": {
      fontSize: 12,
    },
  },
  "& .phones-name": {
    display: "flex",
    gap: "21px",
    margin: "24px 0 0 -30px",
    flexWrap: "wrap"
  },
  "& .product-text-bold": {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 700,
    color: "#212324",
    margin: "24px 0 7px 0",
    lineHeight: "28px",
  },
  "& .product-p-text": {
    color: "#4F5356",
    fontSize: 15,
    fontWeight: 400,
    lineHeight: "26px",
    fontFamily: "Inter",
    marginBottom: "5px 0 15px 0",
  },
  "& .product-li-text": {
    color: "#4F5356",
    fontSize: 20,
    fontWeight: 400,
    lineHeight: "28px",
    fontFamily: "Inter",
    marginLeft: "9px",
  },
  "& .product-buttons-main-box": {
    gap: 20,
    marginTop: 24,
    display: "flex",
    flexWrap: "wrap",
  },
  "& .product-buttons-box": {
    display: "flex",
    cursor: "pointer",
    borderRadius: "8px",
    gap: "10px",
    padding: "10px 16px",
    background: "#F5F5F4",
    alignItems: "center",
    border: "1px solid #AEB0B1",
  },
  "& .product-buttons-box-red": {
    gap: "10px",
    alignItems: "center",
    cursor: "pointer",
    background: "#DF4833",
    padding: "10px 16px",
    display: "flex",
    borderRadius: "8px",
  },
  "& .product-button-text": {
    color: "#4F5356",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
  },
  "& .product-button-text-white": {
    fontWeight: 400,
    color: "#FFFFFF",
    fontSize: "16px",
    fontFamily: "Inter",
  },
});
// Customizable Area End
